import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { MainBody, Container, GlobalStyles } from './styles/Global.styled';
import './styles/accessibility.css';
import { theme } from './utils/Theme';
import Showcase from "./components/Showcase";
import MySkills from "./components/MySkills";
import MyProjects from "./components/MyProjects";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import TopFadeImage from "../src/assets/top.png";
import AnimatedCursor from "react-animated-cursor";
import Experience from "./components/Experience";
import Posts from "./components/Posts";
import BlogPost from "./components/BlogPost";
import AccessibilityToolbar from './components/AccessibilityToolbar';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <AccessibilityToolbar />
          {/* Skip to main content link for keyboard users */}
          <a 
            href="#main-content" 
            className="skip-link sr-only"
          >
            Skip to main content
          </a>
          <div role="alert" aria-live="polite">
            <Toaster />
          </div>
          <MainBody>
            <header role="banner">
              <Navbar />
            </header>
            <main id="main-content" role="main">
              <Container>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <>
                        <Showcase />
                        <Posts />
                        <Experience />
                        <MySkills />
                        <MyProjects />
                      </>
                    }
                  />
                  <Route path="/diary/:slug" element={<BlogPost />} />
                </Routes>
              </Container>
              <img src={TopFadeImage} alt="Decorative fade effect" style={{ width: 'inherit' }} />
              <Footer />
            </main>
          </MainBody>
          <AnimatedCursor
            innerSize={16}
            outerSize={28}
            color="136, 171, 142"
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={5}
          />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
