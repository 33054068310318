import React from "react";
import { motion } from "framer-motion";
import {resumeLink} from "../utils/Data"
import {
  PaddingContainer,
  Heading,
  ParaText,
  FlexContainer,
  BlueText,
  IconContainer,
  Button,
} from "../styles/Global.styled";
import { BsLinkedin, BsTwitterX, BsFacebook  } from "react-icons/bs";
import { SiGmail, SiLeetcode, SiWhatsapp, SiYoutube } from "react-icons/si";
import {
  ShowcaseParticleContainer,
  ShowcaseImageCard,
  Image,
  Particle,
  StyledLink,
  ResumeButoon,
} from "../styles/Showcase.styled";
import showcaseImage from "../assets/profile2.jpg"
import BackgroundImage from "../assets/particle.png";
import { fadeInLeftVariant, fadeInRightVariant } from "../utils/Variants";
import { TypeAnimation } from "react-type-animation";

const Showcase = () => {
  return (
    <PaddingContainer
      id="Home"
      left="3%"
      right="10%"
      top="10%"
      bottom="10%"
      responsiveTop="8rem"
      responsiveLeft="1rem"
      responsiveRight="1rem"
    >
      <FlexContainer align="center" fullWidthChild>
        {/*left content */}
        <motion.div
          style={{ textAlign: "left" }}
          variants={fadeInLeftVariant}
          initial="hidden"
          whileInView="visible"
        >
          <Heading as="h4" size="h4">
            Hello!
          </Heading>
          <Heading as="h2" size="h2" top="0.5rem" bottom="1rem">
            I'm <BlueText>Kihembo Wilbert</BlueText>
          </Heading>
          <Heading as="h3" size="h3">
            I'm a{" "}
            <BlueText>
              <TypeAnimation
                sequence={[
                  "Software Engineer",
                  1000,
                  "Disability Rights Advocate",
                  1000,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </BlueText>
          </Heading>
          <ParaText as="p" top="2rem" bottom="1rem">
          I'm a dedicated Software Engineer living with cerebral palsy. I earned my diploma in Computer Science from International University of East Africa and am currently pursuing a Bachelor's degree in Information Technology at Cavendish University. I am driven by goals and envision a future where technology transforms the lives of people with disabilities. 
          </ParaText>
         

          {/* social icons */}
          <FlexContainer gap="20px" responsiveFlex>
            <IconContainer color="white" size="1.5rem">
              <StyledLink
                target="blank"
                href="https://www.linkedin.com/in/kihembowilb/"
              >
                <BsLinkedin />
              </StyledLink>
            </IconContainer>
            <IconContainer color="white" size="1.5rem">
              <StyledLink target="blank" href="https://x.com/KihemboWilb">
                <BsTwitterX  />
              </StyledLink>
            </IconContainer>
            <IconContainer color="white" size="1.5rem">
              <StyledLink target="blank" href="https://www.facebook.com/kihembo.wilbert">
                <BsFacebook  />
              </StyledLink>
            </IconContainer>
            <IconContainer color="white" size="1.5rem">
              <StyledLink target="blank" href="https://www.youtube.com/@kihembowilbert5433">
                <SiYoutube />
              </StyledLink>
            </IconContainer>
            <IconContainer color="white" size="1.5rem">
              <StyledLink target="blank" href="mailto:wilbert@kihembo.com">
                <SiGmail />
              </StyledLink>
            </IconContainer>
            <IconContainer color="white" size="1.5rem">
              <StyledLink target="blank" href="https://wa.me/256789618464">
                <SiWhatsapp />
              </StyledLink>
            </IconContainer>
          </FlexContainer>
          {/* <ResumeButoon
            href={resumeLink}
            target="blank"
          >
            Resume
          </ResumeButoon> */}
        </motion.div>

        {/* right content */}
        <FlexContainer
          as={motion.div}
          justify="flex-end"
          align="center"
          variants={fadeInRightVariant}
          initial="hidden"
          whileInView="visible"
        >
          <ShowcaseParticleContainer>
            <ShowcaseImageCard>
              <Image src={showcaseImage} alt="profile" />
            </ShowcaseImageCard>
            <Particle
              as={motion.img}
              animate={{
                x: [0, 100, 0],
                rotate: 360,
                scale: [0.7, 0.5, 0.7],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
              }}
              src={BackgroundImage}
              alt="particle"
              top="-100px"
              left="30px"
              rotate="40deg"
            />
            <Particle
              as={motion.img}
              animate={{
                y: [0, 100, 0],
                rotate: -360,
                scale: [0.7, 0.5, 0.7],
              }}
              transition={{
                duration: 20,
                repeat: Infinity,
              }}
              src={BackgroundImage}
              alt="particle"
              top="100px"
              right="-80px"
              rotate="10deg"
            />
            <Particle
              as={motion.img}
              animate={{
                y: [0, -100, 0],
                rotate: -360,
                scale: [0.7, 0.5, 0.7],
              }}
              transition={{
                duration: 15,
                repeat: Infinity,
              }}
              src={BackgroundImage}
              alt="particle"
              bottom="10px"
              left="-90px"
              rotate="50deg"
            />
          </ShowcaseParticleContainer>
        </FlexContainer>
      </FlexContainer>
    </PaddingContainer>
  );
};

export default Showcase;
