import { createClient } from '@sanity/client';

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID || 'kjhyxlb1',
  dataset: process.env.REACT_APP_SANITY_DATASET || 'production',
  useCdn: true, // `false` if you want to ensure fresh data
  apiVersion: '2024-01-26', // Use today's date or your preferred version
  token: process.env.REACT_APP_SANITY_TOKEN // Add this if you need authenticated requests
});

export default client;
