import React, { useState } from "react";
import {
  PaddingContainer,
  Heading,
  BlueText,
  FlexContainer,
  Button,
} from "../styles/Global.styled";
import { ContactForm, FormInput, FormLabel } from "../styles/Footer.styled";
import { motion } from "framer-motion";
import { fadeInBottomVariant } from "../utils/Variants";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import { FiSend } from 'react-icons/fi';

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!name || !email || !message) {
      toast.error("Please fill in all fields.");
      return;
    }

    setIsLoading(true);

    const service_id = "service_bo99mkw";
    const template_id = "template_a1tcfzu";
    const public_key = "6Q5bdPbya6gK5_T-Y";
    const template_params = {
      name: name,
      email: email,
      message: message,
      sender_email: email,
      sender_name: name,
      reply_to: email,
      to_name: "Kihembo Wilbert",
      subject: `New Contact Form Message from ${name}`,
      user_email: email,
      contact_number: Date.now().toString()
    };

    try {
      await emailjs.send(service_id, template_id, template_params, public_key);
      toast.success("Message sent successfully!");
      setName("");
      setEmail("");
      setMessage("");
    } catch (err) {
      console.error("Email sending error:", err);
      toast.error("Error sending message. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PaddingContainer
      id="Contact"
      top="10%"
      bottom="10%"
      responsiveRight="1rem"
      responsiveLeft="1rem"
      style={{
        overflow: "hidden",
        background: "rgba(255, 255, 255, 0.02)",
        borderRadius: "20px",
        backdropFilter: "blur(10px)",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Heading
        as={motion.h2}
        variants={fadeInBottomVariant}
        initial="hidden"
        whileInView="visible"
        size="h2"
        align="center"
        top="0.5rem"
      >
        Contact <BlueText>Me Here</BlueText>
      </Heading>

      <PaddingContainer top="3rem">
        <FlexContainer justify="center">
          <ContactForm
            as={motion.form}
            variants={fadeInBottomVariant}
            initial="hidden"
            whileInView="visible"
            onSubmit={handleSubmit}
            style={{
              maxWidth: "600px",
              width: "100%",
              padding: "2rem",
              background: "rgba(255, 255, 255, 0.03)",
              borderRadius: "15px",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.07)",
            }}
          >
            <PaddingContainer bottom="2rem">
              <FormLabel>Name:</FormLabel>
              <FormInput
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  transition: "all 0.3s ease",
                  ':focus': {
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  }
                }}
              />
            </PaddingContainer>
            <PaddingContainer bottom="2rem">
              <FormLabel>Email:</FormLabel>
              <FormInput
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  transition: "all 0.3s ease",
                  ':focus': {
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  }
                }}
              />
            </PaddingContainer>
            <PaddingContainer bottom="2rem">
              <FormLabel>Message:</FormLabel>
              <FormInput
                as="textarea"
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  minHeight: "150px",
                  resize: "vertical",
                  transition: "all 0.3s ease",
                  ':focus': {
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  }
                }}
              />
            </PaddingContainer>

            <FlexContainer justify="center" responsiveFlex>
              <Button
                as={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={isLoading}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  cursor: isLoading ? "not-allowed" : "pointer",
                  opacity: isLoading ? 0.7 : 1,
                  background: "linear-gradient(45deg, #007bff, #00bfff)",
                  border: "none",
                  padding: "0.8rem 2rem",
                  borderRadius: "30px",
                  fontSize: "1rem",
                  fontWeight: "600",
                  color: "#fff",
                  transition: "all 0.3s ease",
                  boxShadow: "0 4px 15px rgba(0, 123, 255, 0.3)",
                }}
              >
                {isLoading ? (
                  <motion.span
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  >
                    ⟳
                  </motion.span>
                ) : (
                  <>
                    <FiSend /> Send Message
                  </>
                )}
              </Button>
            </FlexContainer>
          </ContactForm>
        </FlexContainer>
      </PaddingContainer>
    </PaddingContainer>
  );
};

export default Footer;
