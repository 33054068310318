import React from "react";
import {
  PaddingContainer,
  Heading,
  BlueText,
  FadeImage,
} from "../styles/Global.styled";
import { motion } from "framer-motion";
import { fadeInTopVariant } from "../utils/Variants";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import left from "../assets/left.png";

const Experience = () => {
  return (
    <PaddingContainer
      id="Experience"
      top="10%"
      bottom="10%"
      responsiveRight="1rem"
      responsiveLeft="1rem"
    >
      <FadeImage src={left} left="0" width="inherit" />

      <Heading
        as={motion.h4}
        variants={fadeInTopVariant}
        initial="hidden"
        whileInView="visible"
        size="h4"
        align="center"
      >
        
      </Heading>
      <Heading
        as={motion.h2}
        variants={fadeInTopVariant}
        initial="hidden"
        whileInView="visible"
        size="h2"
        align="center"
      >
        My <BlueText>Experience</BlueText>
      </Heading>
      <PaddingContainer top="5%">
        <VerticalTimeline>
          <VerticalTimelineElement
            contentStyle={{ background: "#191919", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  #191919" }}
            date="June 2024- present"
            iconStyle={{ background: "#88AB8E", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
            Coordinator
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            E-Learning for Inclusive Education
            </h4>
            <p>
            Overseeing teacher training, workshops, resource development, and stakeholder communication to implement inclusive e-learning initiatives for students with disabilities in the Rwenzori Region, funded by the Luke 4 Foundation and implemented by Embrace International Canada.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: "#191919", color: "#88AB8E" }}
            contentArrowStyle={{ borderRight: "7px solid  #191919" }}
            date="Apirl 2024- May 2024"
            iconStyle={{ background: "#88AB8E", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Cohort 55 Participant 
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            Young African Leaders Initiative (YALI) program
            </h4>
            <p>
            My YALI learning experience was transformative, equipping me with essential skills in design thinking, critical thinking, and project management. I gained valuable insights from esteemed speakers and mentors, which fostered leadership and promoted Pan-African collaboration. The program culminated in pitching innovative solutions and receiving recognition, solidifying my commitment to driving positive change in my community.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{ background: "#191919", color: "#88AB8E" }}
            contentArrowStyle={{ borderRight: "7px solid  #191919" }}
            date="July 2023 -   June 2024"
            iconStyle={{ background: "#88AB8E", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Panelist
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Show Abilities Uganda (SAU)
            </h4>
            <p>
            Participating as a panelist in the "Amaani Fund," a participatory grant-making project funded by The Fund for Global Human Rights. Contributing to the selection process of grant recipients and ensuring the allocation of funds to initiatives that promote human rights and social inclusion for marginalized communities.            </p>
          </VerticalTimelineElement>
          
          <VerticalTimelineElement
            contentStyle={{ background: "#191919", color: "#88AB8E" }}
            contentArrowStyle={{ borderRight: "7px solid  #191919" }}
            date="March 2022 - Present"
            iconStyle={{ background: "#88AB8E", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
            Youth Representative
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
            Commonwealth Disabled People's Forum (CDPF)
              
            </h4>
            <p>
            Serving as a youth representative advocating for the rights and empowerment of persons with disabilities within the Commonwealth Disabled People's Forum. Engaging in policy discussions, community outreach, and organizing initiatives to foster inclusivity and support for youth with disabilities globally
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            contentStyle={{ background: "#191919", color: "#88AB8E" }}
            contentArrowStyle={{ borderRight: "7px solid  #191919" }}
            date="Aug 2021- Present"
            iconStyle={{ background: "#88AB8E", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              CEO
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Snow FM Kasese
            </h4>
            <p>
            Founded and currently hosting an online radio station dedicated to advocating for the rights of persons with disabilities in urban areas of Kasese. Using the platform to raise awareness, promote inclusivity, and amplify the voices of marginalized communities through educational and empowerment-focused programming...
            </p>
          </VerticalTimelineElement>
         </VerticalTimeline>
      </PaddingContainer>
    </PaddingContainer>
  );
};

export default Experience;
