import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const AccessibilityIcon = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: 3px solid ${({ theme }) => theme.colors.white};
    outline-offset: 3px;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 28px;
    top: 15px;
    right: 15px;
  }

  @media (max-width: 480px) {
    width: 46px;
    height: 46px;
    font-size: 26px;
    top: 10px;
    right: 10px;
  }
`;

const DropdownMenu = styled.div`
  position: fixed;
  top: 75px;
  right: 20px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 12px;
  z-index: 999;
  min-width: 250px;

  @media (max-width: 768px) {
    right: 10px;
    min-width: 200px;
  }

  @media (max-width: 480px) {
    position: fixed;
    top: auto;
    bottom: 0;
    right: 0;
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary}40;

  &:last-child {
    border-bottom: none;
  }
`;

const MenuButton = styled.button`
  background: ${({ active }) => (active ? '#ffffff20' : 'transparent')};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  margin-left: auto;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.white};
    outline-offset: 2px;
  }
`;

const ReaderPopup = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: ${({ theme }) => theme.colors.primary};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
  width: 300px;

  @media (max-width: 480px) {
    width: 100%;
    right: 0;
    bottom: 0;
    border-radius: 8px 8px 0 0;
  }
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

const SpeedControl = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="range"] {
    flex: 1;
  }
`;

const AccessibilityToolbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [fontSize, setFontSize] = useState(100);
  const [highContrast, setHighContrast] = useState(false);
  const [reducedMotion, setReducedMotion] = useState(false);
  const [showReader, setShowReader] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [speechRate, setSpeechRate] = useState(1);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        setVoices(availableVoices);
        setSelectedVoice(availableVoices[0]);
      }
    };

    loadVoices();
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = loadVoices;
    }

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const changeFontSize = (change) => {
    const newSize = fontSize + change;
    if (newSize >= 90 && newSize <= 150) {
      setFontSize(newSize);
      document.documentElement.style.fontSize = `${newSize}%`;
    }
  };

  const toggleHighContrast = () => {
    setHighContrast(!highContrast);
    document.body.classList.toggle('high-contrast');
  };

  const toggleReducedMotion = () => {
    setReducedMotion(!reducedMotion);
    document.body.classList.toggle('reduced-motion');
  };

  const toggleReader = () => {
    if (!showReader) {
      stopReading();
    }
    setShowReader(!showReader);
  };

  const startReading = () => {
    if (!isReading) {
      const textToRead = document.querySelector('main').textContent;
      const utterance = new SpeechSynthesisUtterance(textToRead);
      
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }
      
      utterance.rate = speechRate;
      utterance.onend = () => setIsReading(false);
      
      setIsReading(true);
      window.speechSynthesis.speak(utterance);
    }
  };

  const stopReading = () => {
    window.speechSynthesis.cancel();
    setIsReading(false);
  };

  return (
    <>
      <AccessibilityIcon
        onClick={toggleDropdown}
        aria-label="Accessibility options"
        title="Accessibility options"
      >
        ♿
      </AccessibilityIcon>

      <DropdownMenu show={isOpen} ref={dropdownRef}>
        <MenuItem>
          <span>Font Size</span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <MenuButton onClick={() => changeFontSize(-10)} title="Decrease font size">A-</MenuButton>
            <MenuButton onClick={() => changeFontSize(10)} title="Increase font size">A+</MenuButton>
          </div>
        </MenuItem>

        <MenuItem>
          <span>High Contrast</span>
          <MenuButton
            onClick={toggleHighContrast}
            active={highContrast}
            title="Toggle high contrast"
          >
            {highContrast ? '☀️ On' : '🌙 Off'}
          </MenuButton>
        </MenuItem>

        <MenuItem>
          <span>Reduced Motion</span>
          <MenuButton
            onClick={toggleReducedMotion}
            active={reducedMotion}
            title="Toggle reduced motion"
          >
            {reducedMotion ? '⚡ On' : '✨ Off'}
          </MenuButton>
        </MenuItem>

        <MenuItem>
          <span>Text Reader</span>
          <MenuButton
            onClick={toggleReader}
            active={showReader}
            title="Toggle text reader"
          >
            🔊 Reader
          </MenuButton>
        </MenuItem>
      </DropdownMenu>

      <ReaderPopup show={showReader}>
        <h3 style={{ margin: '0 0 10px 0', color: 'white' }}>Text Reader</h3>
        <Select 
          onChange={(e) => setSelectedVoice(voices.find(v => v.name === e.target.value))}
          value={selectedVoice?.name}
        >
          {voices.map(voice => (
            <option key={voice.name} value={voice.name}>
              {voice.name}
            </option>
          ))}
        </Select>
        <SpeedControl>
          <span>Speed:</span>
          <input
            type="range"
            min="0.5"
            max="2"
            step="0.1"
            value={speechRate}
            onChange={(e) => setSpeechRate(parseFloat(e.target.value))}
          />
          <span>{speechRate}x</span>
        </SpeedControl>
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
          <MenuButton
            onClick={startReading}
            disabled={isReading}
          >
            {isReading ? '📢 Reading...' : '▶️ Start'}
          </MenuButton>
          <MenuButton
            onClick={stopReading}
            disabled={!isReading}
          >
            ⏹️ Stop
          </MenuButton>
        </div>
      </ReaderPopup>
    </>
  );
};

export default AccessibilityToolbar;
